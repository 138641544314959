import {
  INTERNAL_HEADER_LINKS,
  LOGEED_IN_HEADER_LINKS,
  ANCHOR_HEADER_LINKS,
  ANCHOR_PORTAL_HEADER_LINKS,
  NUDE_NEWS_LINKS,
  FREEMIUM_USER_HEADER_LINKS,
  LINKS_WITHOUT_FANZONE_FEATURES,
} from '../../../constants/internalHeaderLinks';
import { isNudeDomain } from '../../../utils/helpers';

/**
 * @param {object} user - user context object
 * @param {boolean} isAnchorPortal - is user currently on anchor portal pages
 * @param {boolean} isLoggedIn - is user logged in
 * @param {boolean} isAnchor - is currently logged in user of type anchor
 * @param {boolean} chatAvailable - indicates whether user has chatting features permitted
 */
export default (user, isAnchorPortal, isLoggedIn, isAnchor, chatAvailable) => {
  let links = [];
  let loggedInHeaderLinks;
  const { user: userAccount, features } = user || {};
  const canSeeCalendar = features?.includes('CALENDAR_2020');
  const canSeeShop = features?.includes('SHOW_SHOP_LINK');
  const canSeeSummerPack = features?.includes('SUMMER_PACK_2021');
  const canSeeMarchMadnessLink = features?.includes('MARCH_MADNESS_2024');
  const shouldHideFanzoneFeatures = userAccount?.account?.blockFanzoneAccess;

  if (!chatAvailable || shouldHideFanzoneFeatures) {
    loggedInHeaderLinks = LOGEED_IN_HEADER_LINKS.filter((link) => link.title !== 'Chat');
  } else {
    loggedInHeaderLinks = LOGEED_IN_HEADER_LINKS;
  }

  links = getLinks(isLoggedIn, links, isAnchor, isAnchorPortal, features, loggedInHeaderLinks, shouldHideFanzoneFeatures);

  if (userAccount?.account?.freemiumUser && !user?.activeSubscription && !shouldHideFanzoneFeatures) {
    links = FREEMIUM_USER_HEADER_LINKS;
  }
  if (!canSeeCalendar || canSeeSummerPack) {
    links = links.filter((l) => l.title !== '2021 Calendar');
  }
  if (!canSeeShop) {
    links = links.filter((l) => l.title !== 'Fan Store');
  }
  if (!canSeeMarchMadnessLink) {
    links = links.filter((link) => link.title !== 'March Madness Contest');
  }
  if (!canSeeSummerPack) {
    links = links.filter((l) => l.title !== 'Summer Pack');
  }

  if (isLoggedIn) {
    links = links.map((item) => {
      if (item.loggedInUri) {
        return { ...item, href: item.href + item.loggedInUri };
      }

      return item;
    });
  }


  return links;
};

const getLinks = (isLoggedIn, links, isAnchor, isAnchorPortal, features, existingLinks, shouldHideFanzoneFeatures) => {
  if (isNudeDomain) {
    return NUDE_NEWS_LINKS;
  }
  if (isLoggedIn) {
    if (shouldHideFanzoneFeatures) {
      return LINKS_WITHOUT_FANZONE_FEATURES;
    }

    if (isAnchor) {
      return isAnchorPortal ? ANCHOR_PORTAL_HEADER_LINKS : ANCHOR_HEADER_LINKS;
    }

    const isWhitelisted = features?.includes('ANCHOR_PORTAL');
    links = existingLinks.filter((l) => l.onlyAnchorPortalFeatureEnabled ? isWhitelisted : true);

    return links;
  }

  if (!isNudeDomain) {
    return INTERNAL_HEADER_LINKS;
  }
};
