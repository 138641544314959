import { action, computed, observable } from 'mobx';

import { checkAnchorsBroadcastStatus, checkFanZoneStatus, getAnchors } from '../pages/Anchors/services';
import NNL_LOGO from '../assets/NNLiveAds/ad-nnlive-desktop.png';
import { EXPIRY_TIMES_IN_MS, withCacheStorage } from '../utils/withCache';


const NAKED_NEWS_LIVE_DATA = {
  id: 241,
  name: 'Naked News Live',
  biopic: NNL_LOGO,
};

class AnchorStore {
  @observable isAnchorLive = undefined;
  @observable isNakedNewsLive = false;
  @observable allCurrentAndGuestAnchors = [];
  @observable anchors = { currentAnchors: [], guestAnchors: [], pastAnchors: [] };
  @observable anchorsWithFanzone = [];
  @observable onlineAnchors = {};

  @computed get allAnchors() {

    return [...this.anchors?.currentAnchors, ...this.anchors?.guestAnchors, ...this.anchors?.pastAnchors];
  }

  @computed get mergedFanzoneAndAnchorData() {
    const mergedAnchorData = [];

    this.anchorsWithFanzone.forEach((anchor) => {
      const anchorData = {
        ...anchor,
        ...this.allAnchors?.find((anchorsData) => anchorsData?.id === anchor.id),
      };
      mergedAnchorData.push(anchorData);
    });

    return mergedAnchorData;
  }

  @action setIsAnchorLive = (value) => {
    this.isAnchorLive = value;
  };

  @action setIsNakedNewsLive = (value) => {
    this.isNakedNewsLive = value;
  };

  @action setAnchorsWithFanzone = async() => {
    if (this.anchorsWithFanzone?.length) {
      return;
    }

    const [{ error, data }] = await withCacheStorage([{
      cacheKey: 'fan-zone',
      fn: checkFanZoneStatus,
      expiryTime: EXPIRY_TIMES_IN_MS['2_HOUR'],
    }]);
    if (!error) {
      this.anchorsWithFanzone = [...data?.data, NAKED_NEWS_LIVE_DATA];
      Promise.resolve(data);
    }
  };

  @action setAnchors = async() => {
    if (this.anchors?.currentAnchors?.length) {
      return;
    }
    const [{ error, data }] = await withCacheStorage([{ cacheKey: 'anchors-response', fn: getAnchors, expiryTime: EXPIRY_TIMES_IN_MS['1_HOUR'] }]);

    if (!error) {
      const anchors = data.data;
      anchors.currentAnchors.push(NAKED_NEWS_LIVE_DATA);
      this.allCurrentAndGuestAnchors = [...anchors.currentAnchors, ...anchors.guestAnchors];
      this.anchors = anchors;
      Promise.resolve(data);
    }
  };

  @action getAnchorData = (id) => {
    const currentAnchor = this.anchors?.currentAnchors?.find((anchor) => parseInt(id, 10) === anchor?.id);
    const guestAnchor = this.anchors?.guestAnchors?.find((anchor) => parseInt(id, 10) === anchor?.id);
    const pastAnchor = this.anchor?.pastAnchors?.find((anchor) => parseInt(id, 10) === anchor?.id);

    return currentAnchor || guestAnchor || pastAnchor;
  };

  sortAnchorByLiveStatus = (anchors, onlineAnchors) => {
    const liveAnchors = [];
    const offlineAnchors = [];

    anchors.forEach((anchor) => {
      if (onlineAnchors[anchor.id] !== undefined) {
        liveAnchors.push(anchor);
      } else {
        offlineAnchors.push(anchor);
      }
    });

    return liveAnchors.concat(offlineAnchors);
  };

  @action getAnchorsLiveStatus = async() => {
    const { error, data } = await checkAnchorsBroadcastStatus();
    if (!error) {
      const onlineAnchorsMap = {};
      data.data.forEach((anchor) => onlineAnchorsMap[anchor.id] = anchor?.name);
      this.onlineAnchors = onlineAnchorsMap;
    }

    return [];
  };

  @computed get anchorsSortedByLiveStatus() {
    return this.sortAnchorByLiveStatus(this.mergedFanzoneAndAnchorData, this.onlineAnchors);
  }
}

export default new AnchorStore();
