import { action, observable, computed } from 'mobx';

import { GET_ANCHOR_ID_BY_USERNAME } from '../constants/apiUrls/apiUrls';
import forResponse from '../utils/forResponse';
import http from '../services/http';
import { getAnchorPortalServicesV2 } from '../services/GroupShowServices';

import AnchorStore from './AnchorStore';


const NAKED_NEWS_LIVE_ID = 241;


class TippingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable showTippingModal = false;
  @observable anchor = { name: '', id: null, username: '' };
  @observable spotlightAnchor = { name: '', id: null, username: '' };
  @observable secondAnchor = {};
  @observable tipIsLoading = false;
  @observable isLiveShowTip = true;
  @observable isPunishedByBroadcaster = false;

  @computed get anchorShortUrl() {
    const { name } = this.anchor;
    const { id } = this.anchor;
    let anchorShortUrl = '';

    if (name && id) {
      const anchorName = name.toLowerCase().split(' ').join('-');
      anchorShortUrl = `naked-news-anchor-${anchorName}-a${id}`;
    }

    if (id === NAKED_NEWS_LIVE_ID) {
      anchorShortUrl = 'live';
    }

    return anchorShortUrl;
  }

  @action setSecondAnchor = (value) => {
    this.secondAnchor = value;
  };

  @action setIsLiveShowTip = (value) => {
    this.isLiveShowTip = value;
  };

  findAnchor = async(idToMatch) => {
    const { anchorsWithFanzone } = AnchorStore || [];
    if (!anchorsWithFanzone?.length) {
      await AnchorStore.setAnchorsWithFanzone();
    }
    const anchorData = anchorsWithFanzone?.find((anchor) => anchor?.id === parseInt(idToMatch, 10));

    return anchorData;
  };

  @action
    setTippingModal = async(
      anchor,
      spotlightAnchorNickname,
      spotlightAnchorUsername,
      spotlightAnchorId,
      skipSubscribeCheckForSpotlightTips = false,
      isLiveStream,
    ) => {
      this.tipIsLoading = true;
      if (skipSubscribeCheckForSpotlightTips) {
        this.anchor = anchor;
        this.spotlightAnchor = {
          name: spotlightAnchorNickname,
          id: spotlightAnchorId,
          username: spotlightAnchorUsername,
        };
        this.tipIsLoading = false;
        this.showTippingModal = true;

        return;
      }
      if (anchor.id && !spotlightAnchorNickname) {
        this.anchor = anchor;
        this.tipIsLoading = false;
        this.showTippingModal = true;

        return;
      }

      if (spotlightAnchorId) {
        const anchorData = await this.findAnchor(spotlightAnchorId);
        const { name, id } = anchorData || {};
        this.spotlightAnchor = { name, id, username: name };
        this.tipIsLoading = false;
        this.showTippingModal = true;
      }

      if (!this.anchor?.id) {
        const anchorData = await this.findAnchor(anchor?.id);
        const { name, id } = anchorData || {};
        this.anchor = { name, id, username: name };
      }
      this.showTippingModal = true;
      this.tipIsLoading = false;
      this.tipIsLoading = false;
    };

  @action
    setTipIsLoading = (value) => {
      this.tipIsLoading = value;
    };

  @action
    closeTippingModal = () => {
      this.showTippingModal = false;
      this.resetTippingAnchors();
    };

  getListOfActiveAnchorsMemberSubscribedTo = (username) => {
    const url = GET_ANCHOR_ID_BY_USERNAME.replace('{username}', username);

    return forResponse(
      http.get(url)
    );
  };

  @action setIsPunished = (value) => {
    this.isPunishedByBroadcaster = value;
  };

  @action resetTippingAnchors = () => {
    this.showTippingModal = false;
    this.anchor = { name: '', id: null, username: '' };
    this.spotlightAnchor = { name: '', id: null, username: '' };
    this.secondAnchor = {};
    this.tipIsLoading = false;
    this.isLiveShowTip = true;
  };
}

export default TippingStore;
