import 'react-app-polyfill/ie11';
import './polyfills/includes';
import 'webrtc-adapter';

import React from 'react';
import * as Sentry from '@sentry/browser';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createRoot } from 'react-dom/client';

import { isNudeDomain } from './utils/helpers';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import './css/index.css';


Sentry.init({ dsn: 'https://8d03f006b76b41369c993c5c2e0e8266@o395131.ingest.sentry.io/5246464' });

if (isNudeDomain) {
  document.title = 'Nude News';
}

if (!process.env.REACT_APP_DEV) {
  document.addEventListener('contextmenu', (event) => event.preventDefault());
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6Le1CPkUAAAAADTdUZ9iFjLqmwWP-o9ASts7r_tV"
  >
    <App />
  </GoogleReCaptchaProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
