import { action, observable, runInAction, computed } from 'mobx';

import {
  getChatAccess,
  getTulumStreams,
  getAnchorPortalServices,
  getActiveStream,
} from '../services/RealityShowServices';
import { TEMPTED_DOMAIN } from '../constants/apiUrls/apiUrls';

import LiveBroadcastStore from './LiveBroadcastStore';


const DEFAULT_INTERVAL = 30000;
const CHECK_FOR_NEW_SHOW_DELAY = 3000;
const STATIC_SHOW = 'STATIC';
const EVENT_PARTICIPANTS = 'PARTICIPANT';
const NOT_BROADCASTING = 'NOT_BROADCASTING';
const STREAM = 'streaming';
const NAKED_NEWS_LIVE_ID = 241;
const MAX_STATIC_CAMS_TO_DISPLAY = 12;
const REALITY_SHOW_ROLES = {
  MAIN_PROFILE: 'MAIN_PROFILE',
  PARTICIPANT: 'PARTICIPANT',
};


const BROADCAST_TYPES = {
  STARTING_BROADCAST: 'anchorStartingBroadcast',
  STARTING_SPOTLIGHT: 'temptationStartedSpotlighting',
  JOINED_GROUP_SHOW: 'temptationJoinedVPS',
  STOPPING_BROADCAST: 'anchorStoppingBroadcast',
  TEMPTATION_STOPPING_SPOTLIGHT: 'temptationStoppedSpotlighting',
};

class TulumRealityShowStore {
  featuredStreamIntervalId = null;
  timeoutId1 = null;
  timeoutId2 = null;

  @observable streams = null;
  @observable eventParticipants = [];

  @observable selectedStreamID = null;
  @observable chatAccess = null;
  @observable loadingStream = false;
  @observable hasStreamError = false;
  @observable allowStreamChange = false;
  @observable isFollowingFeaturedStream = false;
  @observable shouldExcludeFeatured = false;

  @observable modelServices = null;
  @observable guestUser = null;
  @observable activeStream = false;
  @observable streamData = null;

  @observable hasNoLiveStream = false;
  @observable isStreamUnavailable = false;
  @observable isNNLStreamActive = false;

  @computed get selectedStream() {
    const selectedStream = this.streamData || {};

    return selectedStream;
  }

  @computed get streamService() {
    return this.modelServices?.filter((service) => service.name === STREAM);
  }

  @computed get hasActiveStream() {
    return this.activeStream;
  }

  @action setIsStreamUnavailable(isUnavailable) {
    this.isStreamUnavailable = isUnavailable;
  }

  @action setHasNoLiveStream(hasStream) {
    this.hasNoLiveStream = hasStream;
  }

  @action selectStream(stream) {
    if (!this.loadingStream) {
      if (this.selectedStreamID === stream.streamUri) {
        return;
      }

      this.streamData = this.streams.find((newStream) => newStream.streamUri === stream.streamUri);
      this.activeStream = true;
      this.selectedStreamID = stream.streamUri;
      this.setIsStreamUnavailable(false);

      if (stream.profileStatus !== NOT_BROADCASTING) {
        this.setIsStreamUnavailable(false);
      } else {
        this.setIsStreamUnavailable(true);
      }

    }
  }

  @action async getRealityShowsStreamsAndStartPoll() {
    const { error, data } = await getTulumStreams();

    runInAction(() => {
      if (!this.featuredStreamIntervalId) {
        this.startFeaturedStreamInterval();
      }
      if (!error) {
        const mainProfiles = data?.data?.find((profile) => profile.realityShowRole === REALITY_SHOW_ROLES.MAIN_PROFILE);
        const staticShows = mainProfiles?.staticCams || [];
        let liveStaticShows = staticShows.slice(0, MAX_STATIC_CAMS_TO_DISPLAY);

        if (staticShows?.length > 0) {
          // Streams are sorted by Admin, we should display the first 12 of them
          if (staticShows?.length >= MAX_STATIC_CAMS_TO_DISPLAY) {
            liveStaticShows = staticShows.slice(0, MAX_STATIC_CAMS_TO_DISPLAY);
          } else {
            liveStaticShows = staticShows;
          }
        }


        const eventParticipants = data?.data?.filter((profile) => profile.realityShowRole === REALITY_SHOW_ROLES.PARTICIPANT);
        const formattedEventParticants = eventParticipants.map((participant) => {
          return {
            ...participant,
            url: `${TEMPTED_DOMAIN}/${participant.nickname}`,
            isLive: participant.profileStatus !== NOT_BROADCASTING,
          };
        });

        const nnlLiveStream = eventParticipants.filter((participant) => participant.username === 'NakedNewsLive'
          && participant.profileStatus !== NOT_BROADCASTING);

        if (nnlLiveStream.length > 0) {
          this.isNNLStreamActive = true;// play nn live stream instead of hedonism
        } else {
          this.isNNLStreamActive = false;
        }

        if (liveStaticShows.length === 1 &&
          (liveStaticShows[0].streamUri !== this.selectedStreamID) &&
          this.selectedStreamID) {
          // This is the case when both static streams are live and then the one which user is watching stops
          // In this case we need to display the offline message, instead of the frozen video feed
          this.setIsStreamUnavailable(true);
        }

        if (liveStaticShows.length > 0) {
          this.setHasNoLiveStream(false);

          if (!this.hasActiveStream) {
            // Load the stream and chat only if there are no active streams to
            // avoid reloading the same stream over and over again
            this.selectedStreamID = liveStaticShows[0].streamUri;
            this.streamData = liveStaticShows?.[0];
            this.activeStream = true;
            if (!this.chatAccess) {
              this.getChatAccess();
            }
          }
        } else {
          this.activeStream = false;
          this.setHasNoLiveStream(true);
        }
        this.streams = liveStaticShows;
        this.eventParticipants = formattedEventParticants;
      }
    });
  }

  @action async getServices(stream) {
    const responseAPservices = await getAnchorPortalServices(stream.username);

    runInAction(() => {
      if (!responseAPservices.error) {
        this.modelServices = responseAPservices.data.data.services;
        this.guestUser = responseAPservices.data.data.username;

        this.getActiveStream(stream.username);
      }
    });
  }

  @action async getActiveStream(username) {
    if (this.streamService) {
      const streamServiceUrl = this.streamService[0].url;
      const streamServiceToken = this.streamService[0].token;

      const { error, data } = await getActiveStream(streamServiceUrl, username, streamServiceToken);

      runInAction(() => {
        if (!error) {
          this.activeStream = true;
          this.streamData = data.data[0];
        } else {
          this.activeStream = false;
        }
      });
    }
  }

  @action async getChatAccess() {
    const { error, data } = await getChatAccess();

    runInAction(() => {
      const { chatServiceUrl, roomName, token, username } = data?.data || {};
      if (!error) {
        this.chatAccess = {
          chatServiceUrl,
          roomName,
          token,
          username,
        };
      }
    });
  }

  @action toggleStreamLoading(isLoading) {
    this.loadingStream = isLoading;
  }

  @action toggleStreamError(hasError) {
    this.hasStreamError = hasError;
  }

  @action toggleAllowStreamChange(canChange) {
    this.allowStreamChange = canChange;
  }

  startFeaturedStreamInterval() {
    const { userCanAccessSpecialEvent } = LiveBroadcastStore;
    if (!userCanAccessSpecialEvent) {
      return;
    }
    this.featuredStreamIntervalId = setInterval(() => {
      this.getRealityShowsStreamsAndStartPoll();
    }, DEFAULT_INTERVAL);
  }

  stopFeaturedStreamInterval() {
    if (this.featuredStreamIntervalId) {
      clearInterval(this.featuredStreamIntervalId);
      this.featuredStreamIntervalId = null;
    }

    if (this.timeoutId1) {
      clearTimeout(this.timeoutId1);
      this.timeoutId1 = null;
    }

    if (this.timeoutId2) {
      clearTimeout(this.timeoutId2);
      this.timeoutId2 = null;
    }
  }

  @action checkForEventShowsFromNotification = (event) => {
    const { anchorId, temptationId, spotlightedTemptationId, type } = event?.content || {};

    const notificationIncludesCurrentAnchor = (this.anchorId === anchorId) ||
      (temptationId === this.anchorId) ||
      (spotlightedTemptationId === this.anchorId);

    if (event) {
      const username = event.anchorUsername || event.temptationUsername;
      const showsToUpdate = this.streams?.filter((show) => show.username === username);
      const participantToUpdate = this.eventParticipants?.filter((participant) => participant.username === username);
      const broadCastStateTypes = [
        BROADCAST_TYPES.STOPPING_BROADCAST,
        BROADCAST_TYPES.TEMPTATION_STOPPING_SPOTLIGHT,
        BROADCAST_TYPES.STARTING_BROADCAST,
        BROADCAST_TYPES.STARTING_SPOTLIGHT,
      ];

      if (notificationIncludesCurrentAnchor &&
        broadCastStateTypes.includes(type)) {
        const broadcastEndTimeoutId = setTimeout(() => {
          this.getRealityShowsStreamsAndStartPoll();
          this.timeoutId1 = broadcastEndTimeoutId;
        }, CHECK_FOR_NEW_SHOW_DELAY);

        return;
      }

      if ((showsToUpdate?.length > 0 || participantToUpdate?.length > 0)) {
        const checkForNewTulumShowStartTimeoutId = setTimeout(() => { // live tempations are updated every second in DB hence this timeout to avoid race condition
          this.getRealityShowsStreamsAndStartPoll();
          this.timeoutId2 = checkForNewTulumShowStartTimeoutId;
        }, CHECK_FOR_NEW_SHOW_DELAY);
      }
    }

  };

  @action reset() {
    this.chatAccess = null;
    this.selectedStreamID = null;
    this.hasStreamError = false;
    this.allowStreamChange = false;
    this.streams = null;
    this.eventParticipants = [];
    this.isFollowingFeaturedStream = false;
    this.shouldExcludeFeatured = false;
    this.stopFeaturedStreamInterval();
    this.activeStream = false;
    this.isStreamUnavailable = false;
  }
}

export default new TulumRealityShowStore();
