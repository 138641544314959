import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Snackbar,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import NotificationStore, { NOTIFICATION_SEVERITY } from '../../store/NotificationStore';


const useStyles = makeStyles((theme) => {
  return {
    snackbar: {
      borderRadius: 30,
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('xs')]: { padding: theme.spacing(1, 2, 1, 1) },
    },
    success: { backgroundColor: theme.palette.success.main },
    info: { backgroundColor: theme.palette.info.main },
    error: { backgroundColor: theme.palette.error.main },
    warning: { backgroundColor: theme.palette.warning.main },
    message: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      '& svg': { marginRight: 10 },
    },
  };
});

const GlobalNotification = () => {
  const classes = useStyles();
  const {
    notificationMessage,
    notificationSeverity,
  } = NotificationStore;

  const getNotificationStyle = () => {
    switch (notificationSeverity) {
      case NOTIFICATION_SEVERITY.SUCCESS:
        return classes.success;
        break;
      case NOTIFICATION_SEVERITY.INFO:
        return classes.info;
        break;
      case NOTIFICATION_SEVERITY.ERROR:
        return classes.error;
        break;
      case NOTIFICATION_SEVERITY.WARNING:
        return classes.warning;
        break;
      default:
        return classes.info;
        break;
    }
  };

  if (!notificationMessage) {
    return null;
  }

  return (
    <Snackbar
      ContentProps={{
        classes: {
          root: getNotificationStyle(),
          message: classes.message,
        },
      }}
      action={(
        <Close
          style={{ pointer: 'cursor' }}
          onClick={() => NotificationStore.dismissNotification()}
        />
      )}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      autoHideDuration={6000}
      message={notificationMessage}
      open={notificationMessage?.length}
      onClose={() => {
        NotificationStore.dismissNotification();
      }}
    />
  );
};

export default observer(GlobalNotification);
