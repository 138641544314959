import React, { createContext, useContext, useEffect, useState } from 'react';

import callApi from '../utils/callApi';
import { CATEGORY_LIST_URL } from '../constants/apiUrls/apiUrls';
import { EXPIRY_TIMES_IN_MS, withCacheStorage } from '../utils/withCache';


const BlogContext = createContext({ categories: {} });

export const BlogProvider = ({ children }) => {
  const [categories, setCategories] = useState({});

  useEffect(() => {
    fetchCategories();
  }, []);

  const getCategories = () => callApi(CATEGORY_LIST_URL, 'get', {});

  const getBlogCategories = () =>
    withCacheStorage([{ cacheKey: 'blog-categories', expiryTime: EXPIRY_TIMES_IN_MS['2_HOUR'], fn: getCategories }]);

  const fetchCategories = async() => {
    getBlogCategories().then((res) => {
      const [{ data }] = res;
      const response = data?.data || data;
      setCategories(response.reduce((categories, category) => {
        return {
          ...categories,
          [category.id]: category.name,
        };
      }, {}));
    });
  };

  return (
    <BlogContext.Provider value={{ categories }}>
      {children}
    </BlogContext.Provider>
  );
};

export const useBlog = () => {
  const { categories } = useContext(BlogContext);

  return { categories };
};
