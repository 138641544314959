import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'simple-react-modal';
import { observer } from 'mobx-react';
import { CloseRounded } from '@material-ui/icons';

import ModalStore from '../../store/ModalStore';
import EmailChangeModalTop from '../../assets/EmailChangeModal/girl-images.png';

import styles from './EmailChangeModal.module.css';


function EmailChangeNotifyModal({ show }) {

  const closeModal = useCallback(() => {
    ModalStore.setShowEmailModal(false);
  }, []);

  return (
    <Modal
      closeOnOuterClick={true}
      containerClassName={styles.modalContainer}
      show={show}
      onClose={closeModal}
    >
      <button
        className={styles.closeButton}
        onClick={closeModal}
      ><CloseRounded /></button>
      <div className={styles.container}>
        <img alt="NakedNewsGirls" src={EmailChangeModalTop} />
        <p>To move forward with payments, please provide your Email Address.</p>
        <Link to="/account" onClick={closeModal}>GO TO SETTINGS</Link>
      </div>
    </Modal>
  );
}

export default observer(EmailChangeNotifyModal);
