import React from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

import Notifications from '../Notifications/Notifications';
import 'url-search-params-polyfill';
import { UserContext, RootStoreContext } from '../../context';
import { BlogProvider } from '../../hooks/useBlog';
import { overrideStatusResponse } from '../../utils/overrideStatusResponse';
import GlobalNotification from '../GlobalNotification/GlobalNotification';
import Routes from '../Routes/Routes';
import FullLoader from '../Loaders/FullLoader';
import CampaignStore from '../../store/CampaignStore';
import PaymentFallbackStore from '../../store/PaymentFallbackStore';
import { getCookie } from '../../utils/helpers';
import { EXPIRY_TIMES_IN_MS, withCacheStorage } from '../../utils/withCache';
import { getSSORedirectUrl, getSSOSessionInfo } from '../../services/SSOServices/SSOServices';
import { isBot } from '../../utils/SEOUtils';

import {
  checkStatus,
  checkStatusTempted,
  getActiveCampaign,
  getAnchorPortalCampaign,
  initializeGlobalSession,
} from './services';


const SSO = {
  ENABLED: 'SSO_ENABLED',
  REDIRECT_URI: 'redirect_uri',
  SESSION: 'sso_session',
  COOKIE: 'SSO_SESSION',
  SOURCE: 'event_promo',
};


@observer
class App extends React.Component {

  static contextType = RootStoreContext;

  state = {
    user: {
      userDetails: {},
      token: '',
      features: [],
    },
    isLoaded: false,
    showCookieConsent: false,
    showPremiumUpgradeBar: false,
    userPremiumExpired: false,
    activeCampaigns: [],
    anchorPortalCampaign: {},
  };

  getRedirectUri = () => window.location.href;

  initiateSSORedirect = async(urlParams, data, error) => {
    if (urlParams.has(SSO.SESSION)) {

      return;
    }

    const { initiateSSORedirect, redirectUrl } = data?.data || {};
    if (!error && initiateSSORedirect && redirectUrl?.length && !getCookie('SSO_SESSION_CHECK')) {
      const oneDay = dayjs().
        add(1, 'day').
        toDate();
      document.cookie = `SSO_SESSION_CHECK=true; expires=${oneDay}`;
      const redirectLocation = new URL(redirectUrl);
      redirectLocation.searchParams.set(SSO.REDIRECT_URI, this.getRedirectUri());
      window.location.href = redirectLocation;
    }
  };

  handleSSOSessionRedirect = async(data, urlParams) => {
    const ssoLoginIsEnabled = data?.data?.features?.includes('SSO_ENABLED');
    const isWebcrawler = isBot(navigator.userAgent);
    const source = urlParams.get('source');
    if (!ssoLoginIsEnabled || (data?.data?.authenticated && source !== SSO.SOURCE) || isWebcrawler) {
      return;
    }
    await this.getUserInfo(urlParams, source);
  };

  getUserInfo = async(urlParams, source) => {
    const sessionToken = urlParams.get(SSO.SESSION);
    const { data: redirectData, error: redirectError } = await getSSORedirectUrl();
    const { data, error } = await getSSOSessionInfo(sessionToken);

    if (data?.data?.token?.length) {
      sessionStorage.token = data?.data?.token;
      sessionStorage.username = data?.data?.user?.username;
      window.location.href = '/';
    } else if (!data?.data?.token) {
      this.initiateSSORedirect(urlParams, redirectData, redirectError);
    }

    return { data, error };
  };

  handlePartnerCode = (urlParams) => {
    const partnerCode = urlParams.get('partner_source');
    if (partnerCode?.length) {
      this.setCookieWithExpiry('partnerSource', partnerCode, 30);
    }
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateCode = urlParams.get('_atc');

    if (affiliateCode) {
      document.cookie = `_atc=${affiliateCode}; expires=0; path=/`;
    }
    this.handleAffiliateTrackingParameter(urlParams);
    const [statusResponse, campaignResponse, anchorCampaignResponse] = await withCacheStorage([
      { noCache: true, fn: checkStatus },
      { expiryTime: EXPIRY_TIMES_IN_MS['15_MINS'], cacheKey: 'campaignActive', fn: getActiveCampaign },
      { expiryTime: EXPIRY_TIMES_IN_MS['15_MINS'], cacheKey: 'campaignAnchorPortal', fn: getAnchorPortalCampaign },
    ]);

    const { data: { data: anchorPortalCampaign } } = anchorCampaignResponse;
    const activeCampaigns = campaignResponse?.data?.data;
    const { error, data } = statusResponse;

    if (data?.data?.authenticated) {
      sessionStorage.username = data?.data?.user?.username;
      sessionStorage.token = data?.data?.user?.token;
    }
    const temptedStatusResponse = await checkStatusTempted();
    this.handleSSOSessionRedirect(data, urlParams);

    CampaignStore.setCurrentCampaign(data?.data, activeCampaigns);
    PaymentFallbackStore.checkForPaymentFailure(urlParams, data?.data);
    const userPremiumExpired = data.data.authenticated && !data.data.user.activeSubscription;
    if (!error) {
      overrideStatusResponse(data, temptedStatusResponse);
    }
    this.setAppState(userPremiumExpired, data?.data, activeCampaigns, anchorPortalCampaign);
    this.initializeGlobalPresence(data?.data);
    this.handlePartnerCode(urlParams);
  }

  setCookieWithExpiry = (cookieKey, cookieValue, days) => {
    const thirtyDaysInMilliseconds = days * 24 * 60 * 60 * 1000;
    const expiryDate = new Date(Date.now() + thirtyDaysInMilliseconds);
    const expires = expiryDate.toUTCString();
    document.cookie = `${cookieKey}=${cookieValue}; expires=${expires}; path=/`;
  };

  handleAffiliateTrackingParameter = (urlParams) => {
    const actParameter = urlParams.get('act');
    if (getCookie('affiliateCode') === actParameter) {
      return;
    }
    if (actParameter) {
      this.setCookieWithExpiry('affiliateCode', actParameter, 30);
    }
  };

  setAppState = (userPremiumExpired, userData, activeCampaigns, anchorPortalCampaign) => {
    this.setState({
      isLoaded: true,
      activeCampaigns,
      user: userData,
      userPremiumExpired,
      anchorPortalCampaign,
      showCookieConsent: !document.cookie.includes('notify_cookie_usage=true'),
    });
    this.context.loggedInUser = userData;
  };

  initializeGlobalPresence = async(data) => {
    const globalPresenceRes = await initializeGlobalSession(data, this.context);
    if (!globalPresenceRes?.data) {
      console.error('Error initializing global presence');
    }
  };

  closePremiumUpgradeBar = () => {
    this.setState({ showPremiumUpgradeBar: false });
  };

  acceptCookies = (event) => {
    event.preventDefault();
    document.cookie = 'notify_cookie_usage=true;max-age=2592000';
    this.setState({ showCookieConsent: false });
  };

  render() {
    const {
      user,
      showPremiumUpgradeBar,
      userPremiumExpired,
      activeCampaigns,
      anchorPortalCampaign,
    } = this.state;

    const notificationsAvailable = !(user?.user && user?.user?.account?.anchor);

    if (!this.state.isLoaded) {
      return <FullLoader />;
    }

    return (
      <>
        <UserContext.Provider value={user}>
          <BlogProvider>
            {notificationsAvailable && <Notifications user={user} />}
            <Routes
              activeCampaigns={activeCampaigns}
              anchorPortalCampaign={anchorPortalCampaign}
              closePremiumUpgradeBar={this.closePremiumUpgradeBar}
              showPremiumUpgradeBar={showPremiumUpgradeBar}
              userPremiumExpired={userPremiumExpired}
            />
            <GlobalNotification />
          </BlogProvider>
        </UserContext.Provider>
      </>
    );
  }
}

export default App;
