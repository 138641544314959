import React from 'react';

import withUnreadConversationsCount from '../../HOC/withUnreadConversationsCount';


@withUnreadConversationsCount
class MobileChatLink extends React.Component {
  render() {
    const { numOfUnreadConversations: num } = this.props;

    return (
      <li>
        <a href="/chat">
          Chat
          {num > 0 && (
            <span className="mobile-chat-link-unread-count">{num}</span>
          )}
        </a>
      </li>
    );
  }
}

export default MobileChatLink;
