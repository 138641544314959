import dayjs from 'dayjs';


export const dateIsGreaterThan7Days = (lastViewedDateString) => {
  const lastViewedDate = dayjs(lastViewedDateString, 'YYYY-MM-DD HH:mm');
  const datePlus7Days = lastViewedDate.add(7, 'day');
  const currentDate = dayjs();

  return currentDate.isAfter(datePlus7Days);
};
