import { isMobileOnly } from 'react-device-detect';

import * as services from '../services/PrivateMessagingServices';


export async function activateConversation({ conversationName, anchor }) {
  if (anchor) {
    this.createConversation(anchor);

    return;
  }
  if (this.activeConversationName === conversationName && !isMobileOnly) {
    return;
  }
  if (!this.conversationSession.loading) {
    this.activeConversationName = conversationName;
    if (isMobileOnly) {
      this.closeMobileSidebar();
    }
    if (this.conversationSession && this.conversationSession.session) {
      this.conversationSession.session.leaveConversation();
    }
    this.activeConversationMessages = [];
    this.conversationSession.loading = true;
    this.conversationSession.error = null;
    this.conversationSession.session = null;
    this.conversationSession.token = '';
    const { data } = await services.getConversationAccessToken(conversationName);

    this.conversationSession.loading = false;
    if (data) {
      this.conversationSession.token = data.data;
      await this.ChatManager.createConversationSessionAsyncWithoutListener(this.rootStore.loggedInUser.account.username, conversationName, data.data, async(session, error) => {
        if (!error) {
          this.conversationSession.session = session;
          try {
            await session.requestRealTimeDatabaseAccess();
            this.conversationSession.session.setConversationListener(this.onConversationEvents.bind(this));
          } catch (e) {
            this.conversationSession.loading = false;
          }
        } else {
          this.conversationSession.error = 'Cannot initialize conversation session.';
        }
      });
    } else {
      this.conversationSession.loading = false;
      this.conversationSession.error = 'Cannot retrieve access token.';
    }
  }
}
