import { action, observable } from 'mobx';

import { dateIsGreaterThan7Days } from '../utils/DateUtils';
import { CALENDAR_DISPLAY } from '../constants/modals';
import { getCookie, isNakedNewsLiveDomain } from '../utils/helpers';
import { getModalsData } from '../components/App/services';

import AnchorStore from './AnchorStore';


const PAGE_TO_HIDE_NICKNAME_POPUP = [
  '/terms_of_service',
  '/privacy_policy',
  '/cookie-policy',
];

class ModalStore {
  @observable showEmailModal = false;
  @observable nicknameCheckDone = false;
  @observable showNicknameCollectModal = false;
  @observable showAgeVerificationModal = false;
  @observable modalsData = [];
  @observable showCalendarModal = false;
  @observable timeout = null;

  @action
    clearTimeout = () => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    };

  @action
    fetchModalsData = async(authenticated) => {
      if (authenticated) {
        const { data: modalsResponse } = await getModalsData();
        this.modalsData = modalsResponse.data;
      }
    };

  @action
    setShowEmailModal = (value) => {
      this.showEmailModal = value;
    };

  @action
    setShowNicknameCollectModal = (value) => {
      this.showNicknameCollectModal = value;
    };

  @action
    setShouldShowCalendarModal = (value) => {
      this.showCalendarModal = value;
    };

  @action
    setShouldShowAgeVerificationModal = (value) => {
      this.showAgeVerificationModal = value;
    };

  @action
    handleCalendarPromoModal = (user, location) => {
      const { pathname } = location || {};
      const calendarModalShown = localStorage.getItem('CALENDAR_MODAL_SHOWN');

      if (
        getCookie('CALENDAR_DISPLAY_2024') ||
      calendarModalShown ||
      !this.shouldNotShowAgeVerificationModal(user) ||
      !user?.features.includes('CALENDAR_2021') ||
      !user?.features.includes('SHOW_SHOP_LINK')
      ) {
        return;
      }

      this.timeout = setTimeout(() => {
        const modal = this.modalsData.find(
          (el) => el.modalType === CALENDAR_DISPLAY
        );
        const exceptions = [
          '/account/purchase',
          '/calendar-purchase',
          '/calendar-checkout',
          '/oauth2/login',
        ];

        const isAnchorPageOrAnchorIsLive =
        /(^\/naked-news-anchor-)/.test(pathname) &&
        (AnchorStore.isAnchorLive || AnchorStore.isAnchorLive === undefined);
        const shouldntShowModal =
        exceptions.includes(pathname) ||
        isAnchorPageOrAnchorIsLive ||
        (!user.authenticated && getCookie('CALENDAR_DISPLAY_2024')) ||
        (user.authenticated &&
          modal &&
          !modal.showModal &&
          !dateIsGreaterThan7Days(modal?.viewedAt)) ||
        calendarModalShown;

        if (!shouldntShowModal) {
          this.setShouldShowCalendarModal(true);
          localStorage.setItem('CALENDAR_MODAL_SHOWN', true);
        }
      }, 5000);
    };

  @action
    setShowNicknameCollectModal = (value) => {
      this.showNicknameCollectModal = value;
    };

  @action
    checkIfUserShouldUpdateNickname = (user, location) => {
      if (PAGE_TO_HIDE_NICKNAME_POPUP?.includes(location?.pathname)) {
        this.setShowNicknameCollectModal(false);

        return;
      }

      if (
        user?.account?.nicknameUpdateCounter === 0 &&
      user?.account?.createdDuringCombinedPromoEvent
      ) {
        this.setShowNicknameCollectModal(true);
      }

      this.nicknameCheckDone = true;
    };

  shouldNotShowAgeVerificationModal = (user) =>
    user?.authenticated ||
    getCookie('AGE_VERIFICATION')?.length > 0 ||
    !isNakedNewsLiveDomain;

  @action
    handleAgeVerificationModal = (user) => {
      if (this.shouldNotShowAgeVerificationModal(user)) {
        return;
      }

      this.showAgeVerificationModal = true;
      this.showCalendarModal = false;
    };
}

export default new ModalStore();
