import qs from 'qs';

import { LOGIN_URL, LOGOUT_URL } from '../constants/apiUrls/apiUrls';

import callApi from './callApi';
import { generateTraceId, generateSpanId, removeCookie } from './helpers';
import { clearCache } from './withCache';


export const doLogin = function(data) {
  return callApi(LOGIN_URL, 'POST', false, data, {
    'X-B3-TraceId': generateTraceId(),
    'X-B3-SpanId': generateSpanId(),
    'X-B3-Sampled': 1,
    'content-type': 'application/x-www-form-urlencoded',
  }, false, false).then((res) => {
    if (res.status === 200) {
      document.cookie = '_membership=1';
      if (
        storeToken({
          token: res.data.token,
          username: res.data.userDetails.username,
        })
      ) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ message: 'API error' });

    }
    if (res.status === 401) {
      return Promise.reject({ message: 'Invalid username or password' });
    }
  }).catch((err) => Promise.reject({ message: 'Invalid username or password' }));
};

export const doLogout = function(data) {
  return callApi(LOGOUT_URL, 'POST', false, data, { 'content-type': 'application/x-www-form-urlencoded' }).then((res) => {
    if (res.status === 200) {
      removeCookie('nn-ah', '/');
      removeCookie('nn-ah', '/api');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('username');
      window.location.href = '/';
    }
  });
};

export function storeToken({ token, username }) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem('token', token);
    window.sessionStorage.setItem('username', username);

    return true;
  }

}
