import { ANCHOR_VIDEOS_AND_GALLERIES_COUNT } from '../constants/apiUrls/apiUrls';

import callApi from './callApi';


const NAKED_NEWS_LIVE_ID = 241;

export const anchorIsNakedNewsLive = (anchorId) => parseInt(anchorId, 10) === NAKED_NEWS_LIVE_ID;

export const capitalize = (str) =>
  str.
    toLowerCase().
    trim().
    split(' ').
    map((word) => word[0].toUpperCase() + word.substr(1)).
    join(' ');

export const capitalizeFirstChar = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const URL_HOST_PARAMS = new URL(window.location.href).host;

export const isNudeDomain = URL_HOST_PARAMS.includes('nudenews');

export const isNakedNewsLiveDomain = URL_HOST_PARAMS.includes('nakednewslive');

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
};

export const generateTraceId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).
      toString(16).
      substring(1);
  }

  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
};

export const generateSpanId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).
      toString(16).
      substring(1);
  }

  return s4() + s4() + s4() + s4();
};

export const getFormattedAmount = (amount, currency = '$', inverse = false) => {
  if (isNaN(amount)) {
    return '';
  }

  let formattedAmount;

  formattedAmount = Number.isInteger(amount) ? amount : amount.toFixed(2);

  return inverse ? `${formattedAmount}${currency}` : `${currency}${formattedAmount}`;
};

export const getPercentageDescription = (percentage) => {
  if (isNaN(percentage)) {
    return '';
  }

  const formattedPercentage = Number.isInteger(percentage) ? percentage : percentage.toFixed(1);

  let percentageDescription;

  if (percentage < 0) {
    percentageDescription = `Down ${Math.abs(formattedPercentage)}% from last month`;
  } else {
    percentageDescription = `Up ${formattedPercentage}% from last month`;
  }

  return percentageDescription;
};

export const isSubscribedToGroupShowAnchor = (user, id) => {
  if (!user.authenticated) {
    return false;
  }

  if (!user?.user?.account?.anchorSubscriptions) {
    return false;
  }

  const existingSubscription = user.user.account.anchorSubscriptions.find((sub) => +sub.subCategory === +id);

  return Boolean(existingSubscription);
};

export const isSubscribedToGroupShowAnchorByUsername = (user, username) => {
  if (!user.authenticated) {
    return false;
  }

  const subscriptions = user?.user?.account?.anchorSubscriptions;
  if (!subscriptions) {
    return false;
  }
  const existingSubscription = subscriptions.find((sub) => sub.anchorUsername === username);

  return Boolean(existingSubscription);
};

export const buildAnchorLink = (anchor, searchParams, ignorePath) => {
  const BASEROUTE = 'naked-news-anchor';
  const searchParameters = searchParams || '';
  if (parseInt(anchor?.id, 10) === NAKED_NEWS_LIVE_ID || (window.location.pathname === '/live' && !ignorePath)) {
    return 'live';
  }

  const nameSegments = anchor?.name?.split?.(' ') || [];
  if (!nameSegments.length) {
    return BASEROUTE;
  }

  const name = nameSegments.join('-');

  return `${BASEROUTE}-${name.toLowerCase()}-a${anchor.id}${searchParameters}`;
};

export const buildAnchorCheckoutLink = (anchorID, anchorURL, promoCode, campaignSource) => {
  const PROMO = promoCode ? `&code=${encodeURIComponent(promoCode)}` : '';
  const source = campaignSource ? `&campaign=${encodeURIComponent(campaignSource)}` : '';
  const anchorCheckoutURL = `${anchorURL}?showPaymentPopUp=1${PROMO}${source}`;
  if (parseInt(anchorID, 10) === NAKED_NEWS_LIVE_ID || window.location.pathname === '/live') {
    return '/live?showPaymentPopUp=1';
  }

  return anchorCheckoutURL.
    replace('{anchorId}', anchorID).
    replace('{anchorShortUrl}', anchorURL).
    replace('/naked-news-anchor', 'naked-news-anchor');
};

export const filterCampaignBasedOnMembershipStatus = (activeCampaign) =>
  activeCampaign.find((a) => a.affiliateCode.includes('XMASS2022'));

export const createHeaders = () => {
  const obj = { username: '', Authorization: '' };
  if (sessionStorage.getItem('username')) {
    obj.username = sessionStorage.getItem('username');
  }
  if (sessionStorage.getItem('token')) {
    obj.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
  }

  return obj;
};

export const fetchGalleriesAndVideosCountForBio = (id, updaterCallback) => {
  const url = encodeURI(ANCHOR_VIDEOS_AND_GALLERIES_COUNT.replace('{id}', id));
  callApi(url, 'get', {}).then((res) => {
    if (updaterCallback) {
      updaterCallback(res.data);
    }
  }).
    catch((err) => {
      console.log(err);
    });
};

export const removeCookie = (cookieName, cookiePath) => {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
  document.cookie = `${cookieName}=;${expires};path=${cookiePath}`;
};

export const sanitizeHTMLString = (string) => string?.replace(/<\/?[^>]+(>|$)/g, '');
