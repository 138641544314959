import http from '../../services/http';
import forResponse from '../../utils/forResponse';
import {
  STATUS_URL,
  GET_ACTIVE_CAMPAIGN,
  MODALS_DATA_URL,
  TEMPTED_STATUS_URL,
  TEMPTED_BACKEND,
  SEND_LIKE_FOR_CONTENT,
} from '../../constants/apiUrls/apiUrls';
import callApi from '../../utils/callApi';


export const checkStatus = () => forResponse(http.get(STATUS_URL));
export const checkStatusTempted = () => forResponse(http.get(TEMPTED_STATUS_URL));

export const getActiveCampaign = () => forResponse(http.get(GET_ACTIVE_CAMPAIGN));

export const getAnchorPortalCampaign = () => forResponse(http.get(GET_ACTIVE_CAMPAIGN, { params: { anchorPortal: true } }));

export const getModalsData = () => forResponse(http.get(MODALS_DATA_URL));

export const initializeGlobalSession = (user, rootStore) => new Promise(async(resolve, reject) => {
  if (!user.features.includes('ANCHOR_PORTAL')) {
    resolve();
  } else {
    try {
      await rootStore.privateMessagingStore.createGlobalPresenceSession(user.authenticated, user.user);
    } catch (e) {
      console.error('Could not create createGlobalPresenceSession', e);
      reject();
    }
    if (
      user.authenticated &&
      user.user
    ) {
      try {
        await rootStore.privateMessagingStore.addMeToGlobalPresence();
        rootStore.privateMessagingStore.attachGlobalEventsListener();
      } catch (e) {
        console.error('Could not attachGlobalEventsListener', e);
        reject();

      }
    }
    try {
      rootStore.privateMessagingStore.attachSystemEventsListener();
      resolve();
    } catch (e) {
      reject(e);
    }
  }
});

export const handleLike = (temptationId, contentType, contentId, isLiked) => {
  const url = SEND_LIKE_FOR_CONTENT.
    replace('{temptationId}', temptationId).
    replace('{contentType}', contentType).
    replace('{contentId}', contentId);

  if (isLiked) {
    return forResponse(http.post(url));
  }

  return forResponse(http.delete(url));


};

