import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Close, Menu } from '@material-ui/icons';
import classnames from 'classnames';

import nudeNewsLogo from '../../assets/nudenews-logo.png';
import anchorPortalLogo from '../../assets/anchor-portal-logo.png';
import nakedNewsLogoWhite from '../../assets/Affiliate/Logos Icons/25_logo1.png';
import nakedNewsLiveLogo from '../../assets/NakedNewsLive/NNL_192x36.png';
import { isNakedNewsLiveDomain, isNudeDomain } from '../../utils/helpers';
import CountdownTimerComponent from '../generalComponents/CountdownTimerComponent/CountdownTimerComponent';


const HeaderLogoAndDropDown = (props) => {
  const {
    activeCampaign,
    toggleMobileHeader,
    isAnchorPortal,
    user,
    userPremiumExpired,
    showMobileHeader,
  } = props;
  const logo = useMemo(() => {
    if (isAnchorPortal) {
      return anchorPortalLogo;
    }

    if (isNudeDomain) {
      return nudeNewsLogo;
    }

    if (isNakedNewsLiveDomain) {
      return nakedNewsLiveLogo;
    }

    return nakedNewsLogoWhite;
  }, [isAnchorPortal]);
  const isNNLogo = useMemo(() => !isAnchorPortal && !isNudeDomain && !isNakedNewsLiveDomain, [isAnchorPortal]);
  const MenuButton = showMobileHeader ? Close : Menu;

  return (
    <div
      className={`navbar-header ${isAnchorPortal ? 'anchor-portal-topnav-header' : ''}`}
      id="navbar-header"
    >
      <button
        className={`${isAnchorPortal ? 'anchor-portal-topnav-toggle' : 'navbar-toggle navbar-toggle-custom'
        }`}
        type="button"
        onClick={toggleMobileHeader}
      >
        <span className="sr-only">Toggle navigation</span>
        <MenuButton fontSize="inherit" />
      </button>
      {(userPremiumExpired || (user && !user.authenticated)) &&
        activeCampaign &&
        activeCampaign.hasTimer &&
        activeCampaign.secondsLeft && (
        <div className="navbar-toggle navbar-toggle-custom navbar-toggle-custom-countdown">
          <CountdownTimerComponent activeCampaign={activeCampaign} />
        </div>
      )}
      <div className="hidden-xs">
        {!isAnchorPortal && <div className={classnames({ 'nn-logo': isNNLogo })} id="red-background" />}
        <Link
          className={classnames('navbar-brand', { 'nn-logo': isNNLogo })}
          to={isAnchorPortal ? '/dashboard' : '/home'}
        >
          <img
            alt="Naked News: The Program With Nothing To Hide"
            className={classnames('img-responsive', { 'anchor-portal-logo': isAnchorPortal })}
            src={logo}
          />
        </Link>
      </div>
      <div className="visible-xs">
        <Link
          className={classnames('navbar-brand', { 'nn-logo': isNNLogo, 'navbar-brand-custom': isAnchorPortal })}
          to={isAnchorPortal ? '/dashboard' : '/home'}
        >
          <img
            alt="Naked News: The Program With Nothing To Hide"
            className={classnames('img-responsive', { 'anchor-portal-logo': isAnchorPortal })}
            src={logo}
          />
        </Link>
      </div>
    </div>
  );
};

HeaderLogoAndDropDown.propTypes = {
  activeCampaign: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleMobileHeader: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isAnchorPortal: PropTypes.bool,
  userPremiumExpired: PropTypes.bool,
};

HeaderLogoAndDropDown.defaultProps = {
  isAnchorPortal: false,
  userPremiumExpired: false,
};

export default HeaderLogoAndDropDown;
