import LiveBroadcastStore from '../../../LiveBroadcastStore';
import PaidFeatureStore from '../../../PaidFeatureStore';
import PayPerViewStore from '../../../PayPerViewStore';
import TulumRealityShowStore from '../../../TulumRealityShowStore';


const EVENT_TYPES = {
  PURCHASE_NOTIFY: 'purchaseNotifyEvent',
  DISABLE_MESSAGE_WRITE_IN_NON_FAN_CONVERSATION: 'DisableCanWriteMessagesInNonFanConversation',
  ENABLE_MESSAGE_WRITE_IN_NON_FAN_CONVERSATION: 'EnableCanWriteMessagesInNonFanConversation',
  PUNISHMENT: 'Punish',
  UNPUNISH: 'Unpunish',
  PAID_FEATURE_PASS: 'PaidFeaturePassPurchased',
};

export function usersPresenceEventHandler(event) {
  if (event.type === 'add') {
    this.usersPresence.set(event.value, true);
  }
  if (event.type === 'remove') {
    this.usersPresence.set(event.value, false);
  }
}

export function systemEventsHandler(event) {
  LiveBroadcastStore.setStreamEventNotification(event);
  TulumRealityShowStore.checkForEventShowsFromNotification(event);
  this.rootStore.uiStore.notify(event);
}

function handleEventByType(eventType, event) {
  switch (eventType) {
    case EVENT_TYPES.PURCHASE_NOTIFY:
      PayPerViewStore.purchaseNotifyEventHandler(event);
      break;
    case EVENT_TYPES.ENABLE_MESSAGE_WRITE_IN_NON_FAN_CONVERSATION:
      this.updateChatState(event.content);
      break;
    case EVENT_TYPES.DISABLE_MESSAGE_WRITE_IN_NON_FAN_CONVERSATION:
      this.updateChatState(event.content);
      break;
    case EVENT_TYPES.UNPUNISH:
      LiveBroadcastStore.handlePunish();
      LiveBroadcastStore.removeFromListOfPunishInStream(event?.content?.temptationId);
      break;
    case EVENT_TYPES.PUNISHMENT:
      LiveBroadcastStore.handlePunish();
      LiveBroadcastStore.pushToListOfPunishInStream(event?.content);
      break;
    case EVENT_TYPES.PAID_FEATURE_PASS:
      PaidFeatureStore.handlePaidFeaturePassPaymentEvent(event?.content?.temptationId);
      break;

    default:
      break;
  }
}

export function globalSessionEventsHandler(event) {
  if (!event) {
    console.error('No event sent! event info:', event);

    return;
  }

  handleEventByType(event?.content?.type, event);

  if (
    event.type === 'CONVERSATION_MESSAGE' &&
    this.rootStore.uiStore.shouldGetNumOfConversationsWithUnreadMessages) {
    this.rootStore.uiStore.getNumOfConversationsWithUnreadMessages();
  }
  // Ignore the new messages of type 'CUSTOM' - Anchors
  // They should appear in the chat preview only for fans
  const anchorMassMessage = event &&
    event.content.type === 'CUSTOM' &&
    event.content.details.type !== 'tippingMessage' &&
    this.rootStore.loggedInUser.account.anchor;

  if (event && this.conversations.data.length && !anchorMassMessage) {
    if (event.type === 'CONVERSATION_MESSAGE') {
      // if (this.activeConversationName !== event.content.conversationName) {
      if (this.activateConversation.name !== event.content.conversationName) {
        this.conversations.data.forEach((c) => {
          if (c.name === event.content.conversationName) {
            if (event.content.type === 'CUSTOM') {
              c.lastCustomMessage = event.content;
            } else {
              c.lastStandardMessage = event.content;
            }
          }
        });
      }

      // Anchor Side - Check if the incoming conversation is already in the list (new conversations won't be in the list)
      const conversationExists = this.conversations.data.
        filter((c) => c.name === event.content.conversationName).
        length === 1;

      // If it's not, than it's a new conversation (fan => anchor) - refresh the list to display it
      if (!conversationExists) {
        this.loadPrivateChatData();
      }
    }
  }

  const conversationExists = this.conversations.data.
    filter((c) => c.name === event.content.conversationName).
    length === 1;

  // Fan Side - if the conversation is coming through the global session, we need to refresh the list
  // Conversation will exist even if it's empty (on the fan's side), so we'll update the list with the new conversation (anchor => fan)
  if (!this.rootStore.loggedInUser.account.anchor && conversationExists) {
    this.loadPrivateChatData();
  }
  this.globalPresenceSession.lastEventData = event;
}

export function onConversationEvents(event) {
  if (event) {
    if (event.type === 'MESSAGE') {
      if (this.activeConversationMessages.length === 0) {
        this.activeConversationMessages.push(event.content);
        this.conversationSession.session.updateLastReadTimestamp(event.content.timestamp);
      } else if (event.content.timestamp !== this.activeConversationMessages[this.activeConversationMessages.length - 1].timestamp) {
        this.activeConversationMessages.push(event.content);
        this.conversationSession.session.updateLastReadTimestamp(event.content.timestamp);
      }
    }
    if (event.type === 'LAST_READ_UPDATE') {
      this.conversations.data.filter((c) => c.name === this.activeConversationName)[0].requester.lastReadTimestamp = event.content.timestamp;
    }
  }
}

