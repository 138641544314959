import LOGO1 from '../assets/Affiliate/Logos Icons/quality-content_icon.jpg';
import LOGO2 from '../assets/Affiliate/Logos Icons/payout_icon.jpg';
import LOGO3 from '../assets/Affiliate/Logos Icons/latest-tools_icon.jpg';
import NN_LOGO from '../assets/Affiliate/Logos Icons/NN-logo.png';
import NN_LIVE_LOGO from '../assets/Affiliate/Logos Icons/NNLive_logo.png';
import FANZONE_LOGO from '../assets/Affiliate/Logos Icons/fanzone_logo.png';
import anchorBG1 from '../assets/Affiliate/marina-mobile.jpg';
import anchorBG2 from '../assets/Affiliate/isabella-mobile.jpg';
import anchorBG3 from '../assets/Affiliate/eila_mobile.jpg';
import CAM4_LOGO from '../assets/Affiliate/cam-4-logo.png';
import NN_LOGO_TRANSPARENT from '../assets/Affiliate/naked-news-in-red.png';
import TEMPTED_LOGO from '../assets/Affiliate/tempted_red.png';


export const AFFILIATE_LOGIN_URL = 'https://nakednews.everflowclient.io/';

export const AFFILIATE_TEXT_MIDDLE = [
  {
    image: LOGO1,
    title: 'QUALITY CONTENT',
    desc: 'Naked News has \"nothing to hide\". With our high quality and exclusive offers, promoting top converting content has never been easier.',
    className: '',
  },
  {
    image: LOGO2,
    title: 'PAYOUT',
    desc: 'Competitive payouts. Start promoting with us to earn {affiliatePercentage} revenue share.',
    className: '',
  },
  {
    image: LOGO3,
    title: 'LATEST TOOLS',
    desc: 'Access the latest tools, including landing pages and banners for Naked News and FANZONE. Custom promotional content ideas? Speak to us!',
    className: '',
  },
];

export const AFFILIATE_TEXT_BOTTOM = [
  {
    backgroundImageMobile: anchorBG1,
    logo: NN_LOGO,
    desc: 'Stay entertained and informed with current events and special features - delivered completely naked by gorgeous anchors. Never miss breaking news, sports, weather and entertainment with Naked News.',
  },
  {
    backgroundImageMobile: anchorBG2,
    logo: FANZONE_LOGO,
    desc: 'Subscribe to FANZONE and get even closer to your favorite anchors. Access special content including videos and galleries, watch live streams and chat privately.',
  },
  {
    backgroundImageMobile: anchorBG3,
    logo: NN_LIVE_LOGO,
    desc: 'Subscribe to Naked News LIVE to enjoy weekly shows with the best selection of guests. Access all Naked News LIVE events and unlock a personal experience',
  },
];

export const AFFILIATE_BRANDS_SECTION = [
  {
    image: CAM4_LOGO,
    alt: 'CAM4',
    title: 'HAS IT ALL',
    desc: 'CAM4 features industry legends, amateurs, and everyone in between! Whether you\'re looking to cater to an existing niche or create a brand new one, CAM4 has it all.',
    link: 'https://www.cam4.com',
    ctaButtonText: 'EXPLORE CAM4',
    className: 'brand',
  },
  {
    image: NN_LOGO_TRANSPARENT,
    alt: 'nakedNEWS',
    title: 'HAS NOTHING TO HIDE',
    desc: 'Naked News has "nothing to hide". Stay entertained and informed with current events and special features, delivered completely naked by gorgeous anchors.',
    link: 'https://www.nakednews.com',
    ctaButtonText: 'EXPLORE NAKED NEWS',
    className: 'brand',
  },
  {
    image: TEMPTED_LOGO,
    alt: 'Tempted',
    title: 'WHERE PASSION MEETS OPPORTUNITY',
    desc: 'Discover the ultimate fansite experience where creators and fans connect like never before. Join a vibrant community and enjoy exclusive content!',
    link: 'https://www.tempted.com',
    ctaButtonText: 'EXPLORE TEMPTED',
    className: 'brand',
  },
];
