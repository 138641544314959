export const loadNextMessages = (nextMethod, timestamp, page) => new Promise((resolve) => {
  nextMethod(timestamp, page, 1000000, (error, messages) => {
    if (!error) {
      resolve({ messages });
    }
  });
});

export const loadPreviousMessages = (prevMethod, timestamp, page) => new Promise((resolve) => {
  prevMethod(timestamp, page, 1000000, (error, messages) => {
    if (!error) {
      resolve({ messages });
    }
  });
});

const WISH_COMPLETED_MESSAGE = 'You helped my Tempting Wish come true! Your Reward is on the way!';
const WISH_COMPLETED = 'contributorToWishCompleted';
const GEOBLOCKING_NOTIFICATION = 'geoblockingNotification';
const MESSAGE_TEMPLATE = { GEOBLOCKING: '{TEMPTATION_NAME}\'s content is not available in some regions. Don\'t let your subscription expire so you don\'t lose access to the hottest content!' };


const getLastPrivateMessageDetailsForAnchor = (c, me, activeConversationName) => {

  if (!c.lastMessage) {
    if (c.lastCustomMessage?.details?.type === 'tippingMessage') {
      return {
        lastMessageReceived: `I tipped you ${c.lastCustomMessage.details.tipsAmmount}$!`,
        lastMessageReceivedTimestamp: c.lastCustomMessage.timestamp,
      };
    }

    if (c.lastCustomMessage?.details?.type === WISH_COMPLETED) {
      return {
        lastMessageReceived: WISH_COMPLETED_MESSAGE,
        lastMessageReceivedTimestamp: c.lastCustomMessage.timestamp,
      };
    }

    return { lastMessageReceived: 'Start a conversation' };
  }
  const isTippingMessage = c.lastCustomMessage?.details?.type === 'tippingMessage'
    && c.lastCustomMessage.timestamp > c.lastMessage.timestamp;

  const isWishCompletedMessage = c.lastCustomMessage?.details?.type === WISH_COMPLETED
    && c.lastCustomMessage.timestamp > c.lastMessage.timestamp;

  const lastMessageReceivedTimestamp = isTippingMessage ? c.lastCustomMessage.timestamp : c.lastMessage.timestamp;
  const messageRead = ((lastMessageReceivedTimestamp > me.lastReadTimestamp) && (c.lastMessage.username !== me.username));

  let lastMessageReceived;
  if (c.lastMessage.type === 'AUDIO') {
    lastMessageReceived = 'Audio';
  } else if (isWishCompletedMessage) {
    lastMessageReceived = WISH_COMPLETED_MESSAGE;
  } else {
    lastMessageReceived = isTippingMessage ? `I tipped you ${c.lastCustomMessage.details.tipsAmmount}$` : c.lastMessage.message;
  }

  return {
    lastMessageReceived,
    lastMessageReceivedTimestamp,
    messageRead,
  };
};

const computeDisplayedMessage = (lastMessage) => {
  if (lastMessage.type === 'AUDIO') {
    return 'Audio';
  } else if (lastMessage.type === 'CUSTOM') {
    if (lastMessage.details.type === 'privateContentMessage') {
      if (lastMessage.details.privateContent.videoUrl) {
        return 'Private video';
      }
      if (lastMessage.details.privateContent.mainImage) {
        return 'Private gallery';
      }
    }

    if (lastMessage.details.type === GEOBLOCKING_NOTIFICATION) {
      return MESSAGE_TEMPLATE.GEOBLOCKING.replace('{TEMPTATION_NAME}', lastMessage.nickname || lastMessage.username);
    }

    if (lastMessage.details.type === WISH_COMPLETED) {
      return WISH_COMPLETED_MESSAGE;
    }

    if (lastMessage.details.type === 'requestLiveShowMessage') {
      return 'Live show requested';
    }
  }

  return lastMessage.message;
};
const getLastPrivateMessageDetailsForFan = (c, me, activeConversationName) => {
  if (c.lastMessage && c.lastCustomMessage) {
    const isTip = c.lastCustomMessage.details && c.lastCustomMessage.details.type === 'tippingMessage';

    if (c.lastMessage.timestamp > c.lastCustomMessage.timestamp) {
      return {
        lastMessageReceived: computeDisplayedMessage(c.lastMessage),
        lastMessageReceivedTimestamp: c.lastMessage.timestamp,
        messageRead: c.lastMessage.timestamp > me.lastReadTimestamp && c.name !== activeConversationName,
        punished: c?.metadata?.punished,
        details: c?.lastMessage?.details,
      };
    }

    return {
      lastMessageReceived: isTip ? `I tipped you ${c.lastCustomMessage.details.tipsAmmount}$!` : computeDisplayedMessage(c.lastCustomMessage),
      lastMessageReceivedTimestamp: c.lastCustomMessage.timestamp,
      messageRead: isTip ? false : c.lastCustomMessage.timestamp > me.lastReadTimestamp && c.name !== activeConversationName,
      punished: c?.metadata?.punished,
      details: c?.lastMessage?.details,
    };
  }

  if (c.lastMessage) {
    return {
      lastMessageReceived: computeDisplayedMessage(c.lastMessage),
      lastMessageReceivedTimestamp: c.lastMessage.timestamp,
      messageRead: c.lastMessage.timestamp > me.lastReadTimestamp && c.name !== activeConversationName,
      punished: c?.metadata?.punished,
      details: c?.lastMessage?.details,
    };
  }

  if (c.lastCustomMessage) {
    return {
      lastMessageReceived: computeDisplayedMessage(c.lastCustomMessage),
      lastMessageReceivedTimestamp: c.lastCustomMessage.timestamp,
      messageRead: c.lastCustomMessage.timestamp > me.lastReadTimestamp && c.name !== activeConversationName,
      punished: c?.metadata?.punished,
      details: c?.lastMessage?.details,
    };
  }

  return { lastMessageReceived: 'Start a conversation' };
};

export const getLastPrivateMessageDetails = (c, isAnchor, me, activeConversationName) => {
  if (isAnchor) {
    return getLastPrivateMessageDetailsForAnchor(c, me, activeConversationName);
  }

  return getLastPrivateMessageDetailsForFan(c, me, activeConversationName);
};
