export const getPageTitleByDomain = () => {
  const domain = window.location.hostname;
  if (domain.includes('theprogramwithnothingtohide.com')) {
    return 'The Program with nothing to hide';
  }
  if (domain.includes('nakednewslive.com')) {
    return 'Naked News Live';
  }

  if (domain.includes('nakednews.com')) {
    return 'Naked News';
  }

  if (domain.includes('nudenews')) {
    return 'Nude News';
  }

  return 'Naked News';
};

export const isBot = (userAgent) => {
  const botPatterns = [
    /bot/i,
    /crawler/i,
    /spider/i,
    /googlebot/i,
    /bingbot/i,
    /yahoobot/i,
    /baiduspider/i,
    /facebookexternalhit/i,
    /twitterbot/i,
    /rogerbot/i,
    /linkedinbot/i,
    /embedly/i,
    /quora link preview/i,
    /showyoubot/i,
    /outbrain/i,
    /pinterest/i,
    /slackbot/i,
    /vkShare/i,
    /W3C_Validator/i,
  ];

  return botPatterns.some((pattern) => pattern.test(userAgent));
};
