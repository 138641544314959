import callApi from '../utils/callApi';


const LOGGER_VIEWER_URL = 'https://logger007.cam4.com/log/webRTC/nn/ceeblue/stats/viewer/events';
const LOGGER_BROADCASTER_URL = 'https://logger007.cam4.com/log/webRTC/nn/ceeblue/stats/broadcaster/events';

class SaasLogger {
  constructor() {
    this.meta = {};
    this.isBroadcaster = false;
    this.userData = null;
  }

  setIdentity = (id) => {
    console.log('setting identity', id);
  };

  setUserData = (data) => {
    this.userData = data;
  };

  setBroadcaster = (flag) => this.isBroadcaster = flag;

  log = (...payload) => {
    const data = {
      payload,
      timestamp: +new Date(),
      perf: performance.now(),
      userData: this.userData,
    };
    this.submit(data);
  };

  error = (...payload) => {
    const data = {
      payload,
      timestamp: +new Date(),
      perf: performance.now(),
      userData: this.userData,
    };
    console.error(data);
    this.submit(data);
  };

  setMeta = (metaData) => {
    this.meta = metaData;
  };

  submit = (data) => {
    const callURL = this.isBroadcaster ? LOGGER_BROADCASTER_URL : LOGGER_VIEWER_URL;
    try {
      callApi(callURL, 'POST', {}, JSON.stringify({ ...data, ...this.meta }), {}, false, false).catch((err) => {
        console.log('metrics error');
      });
    } catch (err) {
      console.error(err);
    }
  };

}


export default new SaasLogger();
