import React from 'react';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import classnames from 'classnames';

import HeaderLogoAndDropDown from '../headerComponents/HeaderLogoAndDropDown';
import CookieConsent from '../generalComponents/CookieConsent';
import MobileHeaderNav from '../headerComponents/MobileHeaderNav/MobileHeaderNav';
import HeaderNav from '../headerComponents/HeaderNav';
import UpgradeAccountHeader from '../UpgradeAccountHeader/UpgradeAccountHeader';
import { UserContext } from '../../context';
import SaasLogger from '../../services/SaasLogger';
import AnchorStore from '../../store/AnchorStore';
import EmailWhitelistMessage from '../generalComponents/EmailWhitelistMessage';
import FullLoader from '../Loaders/FullLoader';
import '../../pages/Home/css/GuestNavMenu.css';
import { LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER } from '../../constants/Constants';
import ModalStore from '../../store/ModalStore';
import EmailChangeNotifyModal from '../EmailChangeModal/EmailChangeNotifyModal';
import NicknameChangeNotifyModal from '../EmailChangeModal/NicknameChangeNotifyModal';


const CalendarPromoModal = React.lazy(() =>
  import('../CalendarPromoModal/CalendarPromoModal')
);
const AnchorDrawerNavigation = React.lazy(() =>
  import('../headerComponents/AnchorDrawerNavigation')
);
const AnchorPortalHeader = React.lazy(() =>
  import('../headerComponents/AnchorPortalHeader')
);
const AgeVerificationModal = React.lazy(() =>
  import('../Modals/AgeVerificationModal')
);

@observer
class Header extends React.Component {
  static contextType = UserContext;

  state = {
    showMobileHeader: false,
    isMobile: false,
    showAnchorMobileHeader: false,
    showCookieConsent: !document.cookie.includes('notify_cookie_usage=true'),
    showEmailWhitelist: !document.cookie.includes('show_email_whitelist=true'),
  };

  toggleMobileHeader = () => {
    const { showMobileHeader } = this.state;

    this.setState({ showMobileHeader: !showMobileHeader });
  };

  openAnchorMobileHeader = () =>
    this.setState({ showAnchorMobileHeader: true });

  closeAnchorMobileHeader = () =>
    this.setState({ showAnchorMobileHeader: false });

  componentDidMount() {
    const user = this.context;
    const { location } = this.props;
    //  TODO - Remove this and see if it can be handled with pure css
    this.setState({ isMobile: window.innerWidth <= 991 });
    this.setUserData();
    ModalStore.fetchModalsData(user?.authenticated);
    ModalStore.checkIfUserShouldUpdateNickname(user, location);
    ModalStore.handleCalendarPromoModal(user, location);
    ModalStore.handleAgeVerificationModal(user, location);
    window.addEventListener(
      'resize',
      () => {
        if (this.state.isMobile !== window.innerWidth <= 991) {
          this.setState({ isMobile: window.innerWidth <= 991 });
        }
      },
      false
    );
    when(() => AnchorStore.isAnchorLive !== undefined);
  }

  componentDidUpdate(prevProps) {
    const user = this.context;
    const { location } = this.props;
    if (user && prevProps?.location?.pathname !== location?.pathname) {
      ModalStore.checkIfUserShouldUpdateNickname(user, location);
    }
    if (location.pathname !== prevProps.location.pathname) {
      ModalStore.handleCalendarPromoModal(user, location);
    }
  }

  componentWillUnmount() {
    ModalStore.clearTimeout();
  }

  setUserData = () => {
    const user = this.context;
    if (!user.authenticated) {
      return;
    }
    const userData = {
      id: user.user.account.id,
      username: user.user.username,
    };
    if (user.authenticated) {
      Sentry.configureScope((scope) => {
        scope.setUser(userData);
      });
      SaasLogger.setUserData(userData);
    }
  };

  isAnchor = () => {
    const user = this.context;
    let isAnchor = false;
    if (user && user.user && user.user.username) {
      isAnchor = user.user.account.anchor;
    }

    return isAnchor;
  };

  acceptCookies = (event) => {
    event.preventDefault();
    document.cookie = 'notify_cookie_usage=true;max-age=2592000';
    this.setState({ showCookieConsent: false });
  };

  dontShow = () => {
    document.cookie = 'show_email_whitelist=true;max-age=2592000';
    this.removeEmailWhitelistMessage();
  };

  removeEmailWhitelistMessage = () => {
    this.setState({ showEmailWhitelist: false });
  };

  closeMobileHeader = () => {
    this.setState({ showMobileHeader: false });
  };

  render() {
    const {
      showPremiumUpgradeBar,
      closePremiumUpgradeBar,
      userPremiumExpired,
      isAnchorPortal,
      location,
      activeCampaign,
      isAffiliatePage,
      isGuestHomePage,
    } = this.props;
    const { location: { pathname } } = this.props;
    const hideHeaderLoginButton = pathname === '/oauth2/login';
    const user = this.context;
    const {
      showMobileHeader,
      isMobile,
      showAnchorMobileHeader,
      showCookieConsent,
      showEmailWhitelist,
    } = this.state;
    const alreadyMember = document.cookie.includes('_membership=1');
    const {
      showEmailModal,
      showNicknameCollectModal,
      showCalendarModal,
      showAgeVerificationModal,
    } = ModalStore;
    if (
      LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER.includes(
        location?.pathname
      ) ||
      (location.pathname === '/dashboard/broadcast' && isMobile)
    ) {
      return null;
    }

    const isFan = user.authenticated && !user.user.account.anchor;

    return (
      <>
        {showEmailWhitelist && isFan && (
          <EmailWhitelistMessage
            dontShow={this.dontShow}
            removeEmailWhitelistMessage={this.removeEmailWhitelistMessage}
          />
        )}
        {showCookieConsent && <CookieConsent onAccept={this.acceptCookies} />}
        {showPremiumUpgradeBar && (
          <UpgradeAccountHeader
            closePremiumUpgradeBar={closePremiumUpgradeBar}
            isMobile={isMobile}
          />
        )}
        {showCalendarModal && (
          <React.Suspense fallback={null}>
            <CalendarPromoModal
              showCalendarModal={showCalendarModal}
              user={user}
            />
          </React.Suspense>
        )}
        {showAgeVerificationModal && (
          <React.Suspense fallback={null}>
            <AgeVerificationModal showModal={showAgeVerificationModal} />
          </React.Suspense>
        )}
        {showEmailModal && <EmailChangeNotifyModal show={showEmailModal} />}
        {showNicknameCollectModal && (
          <NicknameChangeNotifyModal show={showNicknameCollectModal} />
        )}
        <div id="navigation">
          <div
            className={classnames('navbar', 'navbar-lg', 'navbar-topnav', {
              'anchor-portal-topnav': isAnchorPortal,
              'affiliate-topnav': isAffiliatePage,
              'guest-home-nav': !isAffiliatePage,
              'guest-nav-menu': !isAffiliatePage,
            })}
            id="header-nav"
            role="navigation"
          >
            {isAnchorPortal ? (
              <React.Suspense fallback={<FullLoader />}>
                <AnchorPortalHeader handleClick={this.openAnchorMobileHeader} />
                <AnchorDrawerNavigation
                  anchor="right"
                  open={showAnchorMobileHeader}
                  onClose={this.closeAnchorMobileHeader}
                />
              </React.Suspense>
            ) : (
              <>
                <HeaderLogoAndDropDown
                  activeCampaign={activeCampaign}
                  isGuestHomePage={isGuestHomePage}
                  location={location}
                  showMobileHeader={showMobileHeader}
                  toggleMobileHeader={this.toggleMobileHeader}
                  user={user}
                  userPremiumExpired={userPremiumExpired}
                />
                <MobileHeaderNav
                  activeCampaign={activeCampaign}
                  alreadyMember={alreadyMember}
                  hideHeaderLoginButton={hideHeaderLoginButton}
                  isAffiliatePage={isAffiliatePage}
                  isAnchor={this.isAnchor()}
                  isMobile={isMobile}
                  location={this.props.location}
                  showMobileHeader={showMobileHeader}
                  toggleMobileHeader={this.closeMobileHeader}
                  user={user}
                  userPremiumExpired={userPremiumExpired}
                />
                <HeaderNav isAnchor={this.isAnchor()} user={user} />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Header;
