import React, { useCallback, useContext, useState } from 'react';
import Modal from 'simple-react-modal';
import { observer } from 'mobx-react';

import EmailChangeModalTop from '../../assets/EmailChangeModal/girl-images.png';
import ModalStore from '../../store/ModalStore';
import callApi from '../../utils/callApi';
import { TEMPTED_ACCOUNTS_URL } from '../../constants/apiUrls/apiUrls';
import { RootStoreContext } from '../../context';

import styles from './EmailChangeModal.module.css';


function NicknameChangeNotifyModal({ show }) {
  const rootContext = useContext(RootStoreContext);
  const { loggedInUser } = rootContext;
  const [data, setData] = useState('');
  const [nicknameSet, setNicknameSet] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableInput, setDisableInput] = useState(true);
  const closeModal = useCallback(() => {
    if (!nicknameSet) {
      return;
    }
    ModalStore.setShowNicknameCollectModal(false);
  }, []);

  const handleErrorOnSubmit = (err) => {
    if (err.response.status === 409) {
      setErrorMessage('This nickname is already in use. Please try a different one.');
      setDisableInput(false);
    }
    if (err.response.status === 400) {
      setErrorMessage('The nickname is invalid. Please try a different one');
      setDisableInput(false);
    }
  };

  const handleNicknameChanges = useCallback(() => {
    if (loggedInUser?.account?.nicknameUpdateCounter === 0 && !loggedInUser?.account?.anchor) {
      setDisableInput(true);
      const payload = { nickname: data };
      payload.username = sessionStorage.getItem('username');
      callApi(TEMPTED_ACCOUNTS_URL, 'PUT', false, payload, true, true, true, true).
        then(() => {
          setNicknameSet(true);
          window.location.reload();
        }).
        catch(handleErrorOnSubmit);
    }
  }, [loggedInUser?.account?.nicknameUpdateCounter, loggedInUser?.account?.anchor, data]);

  const onInputChange = useCallback((event) => {
    setErrorMessage('');
    setDisableInput(false);
    const input = event.target.value;
    setData(input.trim());
  }, []);

  return (
    <Modal
      closeOnOuterClick={false}
      containerClassName={styles.modalContainer}
      show={show}
      onClose={closeModal}
    >
      <div className={styles.container}>
        <img alt="NakedNewsGirls" src={EmailChangeModalTop} />
        <h3>You&apos;re almost a member</h3>
        <p className={styles.disclaimer}>Almost there! In order to enjoy your benefits please provide your Nickname.</p>
        <div className={styles.inputContainer}>
          <input
            maxLength={30}
            placeholder="Enter your Nickname"
            type="text"
            value={data}
            onChange={onInputChange}
          />
          <p className={styles.error}>{errorMessage}</p>
        </div>
        <button
          className={styles.submitButton}
          disabled={data?.length < 1 || disableInput}
          onClick={handleNicknameChanges}
        >
          SUBMIT
        </button>
        <small>
          <p className={styles.disclaimer}>
            By continuing, you agree to our&nbsp;
            <a className={styles.link} href={'/terms_of_service'} rel="noreferrer" target="_blank">Terms</a>.
            Learn how we collect, use, and share your data in our&nbsp;
            <a className={styles.link} href={'/privacy_policy'} rel="noreferrer" target="_blank">Privacy Policy</a>,
            and how we use Cookies in our&nbsp;
            <a className={styles.link} href={'/cookie-policy'} rel="noreferrer" target="_blank">Cookie Policy</a>.
          </p>
        </small>
      </div>
    </Modal >
  );
}

export default observer(NicknameChangeNotifyModal);
